<template>
  <a-modal
    v-model="show"
    :footer="null"
    :width="width"
    @cancel="close"
    :closeIcon="null"
    :closable="false"
    class="referral-qr-modal"
    :maskClosable="false"
  >
    <div class="referral-qr-modal__content">
      <div class="referral-qr-modal__header">
        <div class="referral-qr-modal__title">
          <span>{{ labels.appName }}</span>
        </div>
        <div class="referral-qr-modal__close">
          <a-icon type="close" @click="close"></a-icon>
        </div>
      </div>
      <div class="referral-qr-modal__subtitle">
        <span>{{ labels.qrModal.subtitle }}</span>
      </div>
      <div class="referral-qr-modal__body">
        <div class="referral-qr-modal__qr-wrapper">
          <div class="referral-qr-modal__qr">
            <QrcodeVue
              v-if="referralCode"
              :value="referralLink"
              :size="size"
              level="H"
            />
          </div>
        </div>
        <div class="referral-qr-modal__text">
          <span class="referral-label">{{ labels.referralCode }}</span>
          <span class="referral-value">{{ referralCode }}</span>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import EventBus, { ListEvents } from "@/event";
import labels from "@/utils/labels";
import QrcodeVue from "qrcode.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "ReferralQrModal",
  components: { QrcodeVue },
  data() {
    return {
      show: false,
      labels: { ...labels.referral, appName: labels.appName },
    };
  },
  created() {
    EventBus.$on(ListEvents.open_referral_qr_modal, this.open);
    EventBus.$on(ListEvents.close_referral_qr_modal, this.close);
  },
  beforeDestroy() {
    EventBus.$off(ListEvents.open_referral_qr_modal);
    EventBus.$off(ListEvents.close_referral_qr_modal);
  },
  computed: {
    ...mapState({
      screenWidth: (state) => state.layout.screenWidth,
    }),
    ...mapGetters({
      loggedUser: "clientAuth/getLoggedUser",
    }),
    referralCode() {
      return this.loggedUser?.referral_code;
    },
    referralLink() {
      return `${location.origin}${
        this.$router.resolve({
          name: "register",
          query: {
            referralId: this.referralCode,
          },
        }).href
      }`;
    },
    width() {
      return this.screenWidth < 500 ? 360 : 448;
    },
    size() {
      return this.screenWidth < 500 ? 200 : 360;
    },
  },
  methods: {
    close() {
      this.show = false;
    },
    open() {
      this.show = true;
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.referral-qr-modal {
  .ant-modal-content {
    background-color: color(c-secondary);
    border-radius: 0;
    border: 1px solid color(c-hover-wait);
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
  }
  .ant-modal-body {
    padding: 24px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    padding-top: 0;
    .referral-qr-modal__title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: color(--white);
      text-transform: uppercase;
    }
    .referral-qr-modal__close {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: color(--white);
      font-weight: bold;
    }
  }
  &__subtitle {
    font-size: 16px;
    color: color(--white);
    margin-bottom: 20px;
  }
  &__body {
    background-color: color(--white);
    padding: 15px 20px;
    position: relative;
    border-radius: 5px;
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: color(c-secondary);
      border-radius: 50%;
      right: -10px;
      top: 80%;
    }
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: color(c-secondary);
      border-radius: 50%;
      left: -10px;
      top: 80%;
    }
  }
  &__qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 0;
    text-align: center;
    .referral-label {
      font-size: 16px;
      color: #000;
      font-weight: 500;
    }
    .referral-value {
      font-size: 24px;
      color: #000;
      font-weight: 600;
      letter-spacing: 3px;
    }
  }
}
</style>
