import { render, staticRenderFns } from "./AllMyReferralModal.vue?vue&type=template&id=5ec0add6&"
import script from "./AllMyReferralModal.vue?vue&type=script&lang=js&"
export * from "./AllMyReferralModal.vue?vue&type=script&lang=js&"
import style0 from "./AllMyReferralModal.vue?vue&type=style&index=0&id=5ec0add6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_942d38e42bf4b987e783635c88cde9fa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports