<template>
  <a-modal
    v-model="show"
    :footer="null"
    :width="width"
    @cancel="close"
    class="all-my-referral-modal"
    :maskClosable="false"
  >
    <!-- HEADER -->
    <template slot="title">
      <div class="all-my-referral-modal__header">
        <div class="all-my-referral-modal__title">
          <span>
            <font-awesome-icon
              class="ant-icon"
              :icon="['fas', 'user-friends']"
            />
            {{ labels.allReferralModal.title }}
          </span>
        </div>
      </div>
    </template>
    <div class="all-my-referral-modal__content">
      <p class="all-my-referral-modal__subtitle">
        {{ labels.allReferralModal.subtitle }}
      </p>
      <StandardTable
        v-if="show"
        ref="standardTable"
        :fetchService="getAllMyReferrals"
        :columns="columns"
        :locale="locale"
        :handleShowTotal="handleShowTotal"
      />
    </div>
  </a-modal>
</template>

<script>
import EventBus, { ListEvents } from "@/event";
import labels from "@/utils/labels";
import { mapActions, mapState } from "vuex";
import accounting from "accounting";
import StandardTable from "../core/table/StandardTable.vue";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  name: "AllMyReferralModal",
  components: { StandardTable },
  data() {
    return {
      show: false,
      labels: { ...labels.referral, appName: labels.appName },
      loading: false,
      bonus: null,
      applyAmount: null,
      rules: "",
    };
  },
  async created() {
    EventBus.$on(ListEvents.open_all_my_referrals_modal, this.open);
    EventBus.$on(ListEvents.close_all_my_referrals_modal, this.close);
  },
  beforeDestroy() {
    EventBus.$off(ListEvents.open_all_my_referrals_modal);
    EventBus.$off(ListEvents.close_all_my_referrals_modal);
  },
  computed: {
    ...mapState({
      screenWidth: (state) => state.layout.screenWidth,
    }),
    locale() {
      return {
        emptyText: this.labels.allReferralModal.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.allReferralModal.table.columns.ID,
          dataIndex: "id",
          key: "id",
        },
        {
          title: this.labels.allReferralModal.table.columns.commission,
          dataIndex: "commission",
          key: "commission",
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.labels.allReferralModal.table.columns.paid,
          dataIndex: "paid",
          key: "paid",
          customRender: (text) => {
            return (
              <a-tag color={text ? "#28a745" : "#6c757d"}>
                {text ? "Si" : "No"}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.allReferralModal.table.columns.date,
          dataIndex: "date",
          key: "date",
          customRender: (text) => {
            return this.$moment(text).format(DATE_FORMAT.MOMENT_DATE_TIME);
          },
        },
      ];
    },
    width() {
      return this.screenWidth < 620 ? "90%" : 800;
    },
  },
  methods: {
    ...mapActions("clientAuth", ["getAllMyReferrals"]),
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    close() {
      this.show = false;
    },
    open() {
      this.show = true;
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.all-my-referral-modal {
  &__content {
    padding: 0;
    margin: 0;
    color: color(--white);
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: auto;
    max-height: calc(100vh - 200px);
  }
  .ant-modal-content {
    background-color: color(c-secondary);
    border-radius: 0;
    border: 1px solid color(c-hover-wait);
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
  }
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-header {
    background: color(c-primary);
    border-radius: 0;
    .all-my-referral-modal__title {
      color: color(--white) !important;
    }
  }
  .ant-modal-close {
    color: color(--white) !important;
  }
  .ant-table {
    &:hover {
      transition: 0.5s ease;
      background: unset;
    }
    tr.ant-table-row:hover {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.25) !important;
    }
    tr:nth-child(2n) {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.2) !important;
    }
    tr.table-row-disabled,
    tr.table-row-disabled:hover {
      color: color(c-primary);
      background: color(danger-light) !important;
      font-weight: 600;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      transition: 0.5s ease;
      background: none !important;
    }
    &-tbody,
    &-thead {
      color: color(--white) !important;
      tr {
        th {
          color: color(--white) !important;
          background-color: transparent !important;
        }
      }
    }
    &-footer {
      background: transparent;
    }
    .action-button-view {
      color: color(c-hover);
      border-color: color(c-hover);
      transition: all 0.5s ease;
      background-color: color(c-secondary);
      &:hover {
        transition: all 0.5s ease;
        color: color(c-secondary);
        background-color: color(c-hover);
        border-color: color(c-hover);
      }
    }
    .ant-table-placeholder {
      background: color(c-secondary);
      color: color(--white);
      border-radius: 0;
    }
    @media screen and (max-width: $screen-mobile) {
      &-small {
        border: none;
      }
      &-body {
        margin: 0 1px;
      }
    }
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    color: color(c-secondary) !important;
    background-color: color(c-hover) !important;
    border-color: color(c-hover) !important;
  }
  .ant-pagination-item {
    background-color: color(c-hover) !important;
    border-color: color(c-secondary) !important;
    a {
      color: color(c-secondary) !important;
    }
    &-active {
      background-color: color(c-secondary) !important;
      border-color: color(c-hover) !important;
      a {
        color: color(c-hover) !important;
      }
    }
  }
  .ant-pagination {
    color: color(--white) !important;
  }
}
</style>
