<template>
  <a-modal
    v-model="show"
    :footer="null"
    :width="width"
    @cancel="close"
    :closeIcon="null"
    :closable="false"
    class="referral-rules-modal"
    :maskClosable="false"
  >
    <!-- CLOSE BUTTON -->
    <div class="referral-rules-modal__header">
      <div class="referral-rules-modal__close">
        <a-icon type="close" @click="close"></a-icon>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="referral-rules-modal__loading">
      <a-spin :title="labels.loadingRules" />
    </div>
    <div class="referral-rules-modal__content" v-else v-html="rulesText"></div>
  </a-modal>
</template>

<script>
import EventBus, { ListEvents } from "@/event";
import accounting from "accounting";
import labels from "@/utils/labels";
import { mapActions, mapState } from "vuex";
import { CRAUE_CONFIG } from "@/utils/utils";
export default {
  name: "ReferralRulesModal",
  data() {
    return {
      show: false,
      labels: { ...labels.referral, appName: labels.appName },
      loading: false,
      bonus: null,
      applyAmount: null,
      rules: "",
    };
  },
  async created() {
    EventBus.$on(ListEvents.open_referral_rules_modal, this.open);
    EventBus.$on(ListEvents.close_referral_rules_modal, this.close);
    await this.getSystemConfig();
  },
  beforeDestroy() {
    EventBus.$off(ListEvents.open_referral_rules_modal);
    EventBus.$off(ListEvents.close_referral_rules_modal);
  },
  computed: {
    ...mapState({
      screenWidth: (state) => state.layout.screenWidth,
    }),
    width() {
      return this.screenWidth < 620 ? "90%" : 600;
    },
    rulesText() {
      return this.rules
        .replace(
          /{bono}/g,
          accounting.formatMoney(this.bonus, "RD$ ", 2, ",", ".")
        )
        .replace(
          /{applyAmount}/g,
          accounting.formatMoney(this.applyAmount, "RD$ ", 2, ",", ".")
        );
    },
  },
  methods: {
    ...mapActions("systemConfig", ["getCraueConfigByNameClient"]),
    close() {
      this.show = false;
    },
    open() {
      this.show = true;
    },
    async getSystemConfig() {
      try {
        this.loading = true;
        const { data: dataAmount } = await this.getCraueConfigByNameClient(
          CRAUE_CONFIG.VAR_USER_REFERRAL_COMMISSION_AMOUNT
        );
        const { data: dataApplyAmount } = await this.getCraueConfigByNameClient(
          CRAUE_CONFIG.VAR_REFERRAL_COMMISSION_APPLY_AFTER_SPEND_AMOUNT
        );
        const { data: dataRules } = await this.getCraueConfigByNameClient(
          CRAUE_CONFIG.VAR_USER_REFERRAL_RULES
        );
        this.bonus = dataAmount?.value || "N/A";
        this.applyAmount = dataApplyAmount?.value || "N/A";
        this.rules = dataRules?.value || "N/A";
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.referral-rules-modal {
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    color: color(--white);
    font-size: 24px;
  }
  &__content {
    padding: 0;
    margin: 0;
    color: color(--white);
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: auto;
    max-height: calc(100vh - 200px);
  }
  .ant-modal-content {
    background-color: color(c-secondary);
    border-radius: 0;
    border: 1px solid color(c-hover-wait);
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
    p,
    h2,
    ol,
    li {
      color: color(--white);
      font-size: 18px;
    }
    h2 {
      font-size: 24px;
      font-weight: normal;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }
  .ant-modal-body {
    padding: 24px;
  }
}
</style>
