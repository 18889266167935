<template>
  <a-layout class="referral">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-row type="flex" justify="space-between" :gutter="[16, 16]">
        <a-col :span="24">
          <h2 class="referral-title">
            {{ labels.subtitle }}
          </h2>

          <a-button
            type="link"
            class="referral-button"
            @click="showModalMyReferrals"
            size="large"
          >
            {{ labels.viewAllMyReferrals }}
            <a-icon type="down" />
          </a-button>
        </a-col>
      </a-row>
      <a-card class="referral-card">
        <a-row
          type="flex"
          justify="center"
          :gutter="[16, 16]"
          v-if="referralBonus"
        >
          <a-col :span="24">
            <h3 class="referral-title small">
              {{
                labels.subtitle2
                  | replace("{reward}", `$${referralBonus.amount}`)
              }}
            </h3>

            <a-button
              type="link"
              class="referral-button"
              @click="showModalReferralRules"
              size="large"
            >
              {{ labels.viewReferralRules }}
              <a-icon type="right" />
            </a-button>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :span="24" class="referral-card-content">
            <span v-if="referralBonus" class="bonus-content">
              <span class="bonus-content-label">{{ labels.youReceive }}</span>
              <span class="bonus-content-value">{{
                referralBonus.amount | formatReferral
              }}</span>
            </span>
            <span class="referral-content">
              <span class="referral-content-label">{{
                labels.referralCode
              }}</span>
              <span class="referral-content-value" ref="referralCode"
                >{{ referralCode }}
                <a-icon
                  @click="copy('referralCode')"
                  type="copy"
                  theme="filled"
              /></span>
            </span>
            <span class="referral-content">
              <span class="referral-content-label">{{
                labels.referralLink
              }}</span>
              <span class="referral-content-value" ref="referralLink"
                >{{ referralLink }}
                <a-icon
                  @click="copy('referralLink')"
                  type="copy"
                  theme="filled"
              /></span>
            </span>
            <span class="referral-button-content">
              <a-button
                type="default"
                class="invite-button"
                @click="showModalReferralIdBarcode"
                size="large"
              >
                {{ labels.inviteFiends }}
                <a-icon type="qrcode" />
              </a-button>
            </span>
          </a-col>
        </a-row>
      </a-card>

      <!-- RERERRAL BY LEVEL -->
      <a-card
        class="referral-card"
        :loading="loadingCommissionProfile"
        v-if="!loadCommissionProfile || commissionProfile"
      >
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :span="24">
            <h2 class="card-title">{{ labels.tieredReferralSystem.title }}</h2>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :span="24" class="referral-card-content">
            <p
              class="card-description"
              v-html="
                $options.filters.replaceObject(
                  labels.tieredReferralSystem.description,
                  {
                    percentage1:
                      affiliateNetworkCommission?.level1_percentage || 0,
                    percentage2:
                      affiliateNetworkCommission?.level2_percentage || 0,
                  }
                )
              "
            />

            <div class="card-details">
              <span></span>
              <span></span>
              <span class="title">Pagadas</span>
              <span class="title">Pendientes</span>
            </div>
            <div class="card-details">
              <span>{{ labels.tieredReferralSystem.level1.label }}</span>
              <span
                >{{ affiliateNetworkCommission?.level1_percentage || 0 }}%</span
              >
              <span>{{
                affiliateNetworkCommission?.level1_paid_amount | formatMoney
              }}</span>
              <span>{{ level1 | formatMoney }}</span>
            </div>
            <div class="card-details">
              <span>{{ labels.tieredReferralSystem.level2.label }}</span>
              <span
                >{{ affiliateNetworkCommission?.level2_percentage || 0 }}%</span
              >
              <span>{{
                affiliateNetworkCommission?.level2_paid_amount | formatMoney
              }}</span>
              <span>{{ level2 | formatMoney }}</span>
            </div>
          </a-col>
        </a-row>
      </a-card>
    </div>
    <ReferralRulesModal />
    <AllMyReferralModal />
    <ReferralQrModal />
  </a-layout>
</template>
<script>
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "@/utils/utils";
import labels from "@/utils/labels";
import { mapActions, mapGetters } from "vuex";
import accounting from "accounting";
import ReferralQrModal from "@/components/referrals/ReferralQrModal.vue";
import ReferralRulesModal from "@/components/referrals/ReferralRulesModal.vue";
import AllMyReferralModal from "@/components/referrals/AllMyReferralModal.vue";
import EventBus, { ListEvents } from "@/event";
export default {
  components: {
    TitleBreadcrumb,
    ReferralQrModal,
    ReferralRulesModal,
    AllMyReferralModal,
  },
  name: "Referral",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.referral.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.referral.subtitle,
      },
    ],
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadCommissionProfile();
    });
  },
  data() {
    return {
      labels: labels.referral,
      commissionProfile: null,
      loadingCommissionProfile: false,
      level1: 0,
      level2: 0,
    };
  },
  computed: {
    ...mapGetters({
      loggedUser: "clientAuth/getLoggedUser",
    }),
    referralCode() {
      return this.loggedUser?.referral_code;
    },
    referralLink() {
      return `${location.origin}${
        this.$router.resolve({
          name: "register",
          query: {
            referralId: this.referralCode,
          },
        }).href
      }`;
    },
    affiliateNetworkCommission() {
      return this.commissionProfile?.schema?.find(
        (item) => item.type === "AFFILIATE_NETWORK_COMMISSION"
      );
    },
    referralBonus() {
      return this.commissionProfile?.schema?.find(
        (item) => item.type === "REFERRAL_COMMISSION"
      );
    },
  },
  filters: {
    formatReferral(value) {
      if (value === "N/A") {
        return value;
      }
      return accounting.formatMoney(value, "$", 2, ",", ".");
    },
    replace(value, key, replacement) {
      return value.replace(key, replacement);
    },
    replaceObject(value, replacement = {}) {
      return Object.keys(replacement).reduce((acc, key) => {
        // Replace all occurrences of the key in the string
        acc = acc.replace(new RegExp(`{${key}}`, "g"), replacement[key]);
        return acc;
      }, value);
    },
    formatNumber(value) {
      return accounting.formatNumber(value, 0, ",", ".");
    },
    formatMoney(value) {
      return accounting.formatMoney(value, "$ ", 0, ",", ".");
    },
  },
  methods: {
    ...mapActions("clientAuth", [
      "fetchCommissionProfile",
      "fetchCommissionsPending",
    ]),
    showModalMyReferrals() {
      EventBus.$emit(ListEvents.open_all_my_referrals_modal);
    },
    showModalReferralRules() {
      EventBus.$emit(ListEvents.open_referral_rules_modal);
    },
    showModalReferralIdBarcode() {
      EventBus.$emit(ListEvents.open_referral_qr_modal);
    },
    copy(key) {
      const element = this.$refs[key];
      navigator.clipboard.writeText(element.innerText?.trim());
      document.execCommand("copy");
      this.$message.success(this.labels.copied);
    },
    async loadCommissionProfile() {
      try {
        this.loadingCommissionProfile = true;
        const { data } = await this.fetchCommissionProfile();
        this.commissionProfile = data;
        if (this.commissionProfile) {
          this.loadCommissionsPending();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingCommissionProfile = false;
      }
    },
    async loadCommissionsPending() {
      try {
        const { data } = await this.fetchCommissionsPending();
        this.level1 = data?.level1 || 0;
        this.level2 = data?.level2 || 0;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.referral {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
  &-title {
    font-size: 24px;
    font-weight: 500;
    color: color(--white);
    margin-bottom: 8px;
    text-align: center;
    &.small {
      font-size: 14px;
      color: color(--white);
      font-weight: 400;
      margin-bottom: 16px;
    }
  }
  &-button {
    font-size: 14px;
    font-weight: 500;
    color: color(c-hover);
    background-color: color(--primary);
    border-radius: 4px;
    border: none;
    padding: 8px 16px;
    margin-bottom: 16px;
    &:hover,
    &:active {
      background-color: transparent;
    }
  }
  &-card {
    background-color: color(c-secondary);
    border-color: color(c-secondary);
    padding: 1.125rem;
    border-radius: 10px;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: $screen-mobile) {
      padding: 2.125rem 1rem;
      width: 100%;
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: color(--white);
      .bonus-content {
        padding: 20px 15px;
        background-color: color(c-hover-light);
        color: color(c-primary);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        text-align: center;
        &-label {
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 4px;
        }
        &-value {
          font-size: 26px;
          font-weight: 700;
          margin: 0;
          margin-bottom: 0px;
        }
      }
      .referral-content {
        padding: 10px 15px;
        background-color: color(c-primary);
        color: color(--white);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        &-label {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 4px;
        }
        &-value {
          font-size: 16px;
          font-weight: 700;
          margin: 0;
          margin-bottom: 0px;
          .anticon {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .referral-button-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .invite-button {
          font-size: 14px;
          font-weight: 500;
          color: color(c-primary);
          background-color: color(c-hover);
          border-radius: 4px;
          border: none;
          &:hover,
          &:active {
            background-color: color(c-hover);
          }
          @media screen and (max-width: $screen-mobile) {
            width: 100%;
          }
        }
      }
    }
    .card {
      &-title {
        font-size: 24px;
        font-weight: 500;
        color: color(c-hover);
        margin-bottom: 8px;
        @media screen and (max-width: $screen-mobile) {
          font-size: 18px;
        }
      }
      &-description {
        font-size: 16px;
        font-weight: 400;
        color: color(--white);
        margin-bottom: 16px;
        padding-left: 20px;
      }
      &-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        font-size: 24px;
        font-weight: 400;
        color: color(--white);
        margin-bottom: 2px;
        &:last-child {
          margin-bottom: 0;
        }
        span:first-child {
          font-weight: 500;
        }
        span {
          min-width: 25%;
          &.title {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
          }
          &:last-child {
            color: color(warning-light);
          }
        }
        @media screen and (max-width: $screen-mobile) {
          width: 100%;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
